@import url("icons.generated.css");

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
	:root {
		/* Shadcn UI default tokens, replace when design is ready */
		--background: 0 0% 100%;
		--foreground: 240 10% 3.9%;
		--card: 0 0% 100%;
		--card-foreground: 240 10% 3.9%;
		--popover: 0 0% 100%;
		--popover-foreground: 240 10% 3.9%;
		--primary: 240 5.9% 10%;
		--primary-foreground: 0 0% 98%;
		--secondary: 240 4.8% 95.9%;
		--secondary-foreground: 240 5.9% 10%;
		--muted: 240 4.8% 95.9%;
		--muted-foreground: 240 3.8% 46.1%;
		--accent: 240 4.8% 95.9%;
		--accent-foreground: 240 5.9% 10%;
		--destructive: 0 72.22% 50.59%;
		--destructive-foreground: 0 0% 98%;
		--border: 240 5.9% 90%;
		--input: 240 5.9% 90%;
		--ring: 240 5% 64.9%;
		--radius: 0.5rem;
		/* Accelerator tokens */

		--chevron: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0IiBmaWxsPSJub25lIiBzdHJva2U9ImN1cnJlbnRDb2xvciIgc3Ryb2tlLXdpZHRoPSIyIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiIGNsYXNzPSJsdWNpZGUgbHVjaWRlLWNoZXZyb24tZG93biI+PHBhdGggZD0ibTYgOSA2IDYgNi02Ii8+PC9zdmc+");

		/* Border radius */
		--radius: 0.5rem;
	}
}

@layer base {
	button,
	a,
	input,
	select,
	textarea {
		@apply rounded focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-offset-2;
	}

	body {
		@apply bg-white text-gray-900;
		font-feature-settings: "rlig" 1, "calt" 1;
	}

	.prose h1 {
		@apply text-2xl font-bold;
	}
	.prose h2 {
		@apply text-xl font-bold md:text-[2rem];
	}
	.prose h3 {
		@apply text-lg font-bold md:text-2xl;
	}
	.prose h4 {
		@apply text-base font-bold leading-6;
	}
	.prose small {
		@apply text-xs font-normal leading-4;
	}

	.prose h1,
	.prose h2,
	.prose h3,
	.prose h4 {
		@apply mb-2;
	}

	hr {
		margin: 0;
	}
}

@layer utilities {
	/* For Webkit-based browsers (Chrome, Safari and Opera) */
	.scrollbar-hide::-webkit-scrollbar {
		display: none;
	}

	/* For IE, Edge and Firefox */
	.scrollbar-hide {
		-ms-overflow-style: none; /* IE and Edge */
		scrollbar-width: none; /* Firefox */
	}
}
